var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("youtube", {
    ref: "youtube",
    attrs: {
      "video-id": this.videoId,
      height: this.height,
      width: this.width,
      playerVars: this.playerVars
    },
    on: {
      playing: [_vm.forwardYouTubeEvent, _vm.debounceYouTubeEvent],
      ready: _vm.debounceYouTubeEvent,
      ended: _vm.debounceYouTubeEvent,
      paused: _vm.debounceYouTubeEvent,
      buffering: _vm.debounceYouTubeEvent,
      cued: _vm.debounceYouTubeEvent,
      error: _vm.debounceYouTubeEvent
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }