<template>
  <v-card id="disclaimer">
    <v-card-title class="justify-center text-h4 pt-12">
      <v-img
        class="mr-3"
        max-height="50px"
        max-width="50px"
        contain
        src="@/assets/images/wellness/warning.png"
      />
      {{ $t("wellness.v2.disclaimerTitle") }}
    </v-card-title>
    <v-row justify="center" no-gutters style="min-height:75%;">
      <v-col
        cols="10"
        class="text-body-1 text-center"
        align-self="center"
        v-html="$t('wellness.v2.fullDisclaimer')"
      >
        <p class="mb-0" v-html="$t('wellness.v2.fullDisclaimer')" />
      </v-col>
    </v-row>
    <v-row justify="center" no-gutters class="py-7">
      <v-col cols="6" class="d-flex justify-center px-2">
        <v-btn
          id="agree"
          color="primary"
          @click="$emit('accepted')"
          width="100%"
          max-width="200px"
          rounded
        >
          <v-icon class="mr-2" color="white">mdi-check</v-icon>
          {{ $t("wellness.v2.agree") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Disclaimer"
};
</script>
