<template>
  <div>
    <disclaimer
      v-if="showDisclaimer"
      @close="$emit('close')"
      @accepted="$emit('accepted')"
    />
    <v-card v-else color="black" :rounded="$vuetify.breakpoint.xs ? '0' : ''">
      <v-btn
        id="close-button"
        icon
        :x-large="$vuetify.breakpoint.mdAndUp"
        color="white"
        @click="completeVideo"
        class="close-button"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <div class="row-header">
        <p id="title" class="white--text text-h5 ml-3 py-2 mb-0">
          {{ title }}
        </p>
      </div>

      <v-row no-gutters>
        <v-col
          class="d-flex grow flex-nowrap flex-column"
          cols="12"
          :md="showExerciseSteps ? 8 : 12"
          :lg="showExerciseSteps ? 9 : 12"
        >
          <v-row no-gutters class="grow">
            <v-col>
              <VitrueYouTubePlayer
                :url="this.link"
                :height="playerHeight"
                width="100%"
                ref="vitruePlayer"
                @VIDA_YOUTUBE_EVENT="setActiveStep"
                :showControls="!showExerciseSteps"
              ></VitrueYouTubePlayer>
            </v-col>
          </v-row>
          <v-row
            id="footer"
            no-gutters
            align="center"
            class="row footer white--text pa-2 shrink"
          >
            <v-col cols="12" sm="8" md="6" lg="4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs, value }">
                  <div v-bind="attrs" v-on="on" class="d-flex">
                    <span class="disclaimer" id="disclaimer">
                      {{ disclaimer }}
                    </span>
                    <v-icon color="white">{{
                      value ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </div>
                </template>
                <v-card class="pa-3" dark>
                  {{ disclaimer }}
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="3" offset-md="3" lg="2" offset-lg="6">
              <v-btn
                color="white"
                class="full-width"
                outlined
                @click="onRestartClicked"
              >
                <v-icon left> mdi-restart </v-icon>
                {{ $t("wellness.v2.painCoachVideos.startOver") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          id="exerciseSteps"
          cols="12"
          md="4"
          lg="3"
          v-if="showExerciseSteps"
        >
          <video-stepper
            id="playerSelection"
            ref="stepper"
            :maxHeight="playerSelectionHeight"
            :totalDuration="videoMetaData.totalDuration"
            :exerciseSteps="videoMetaData.steps"
            :stepSelected="onStepClicked"
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Disclaimer from "@/components/common/Disclaimer.vue";
import VitrueYouTubePlayer from "@/components/common/exercise-videos/VitrueYouTubePlayer.vue";
import VideoStepper from "./VideoStepper.vue";
import { mapMutations } from "vuex";

export default {
  name: "ExerciseVideo",
  components: {
    Disclaimer,
    VitrueYouTubePlayer,
    VideoStepper
  },
  props: {
    title: String, //these need to be passed and can't be extracted from this.task as the component is used for non-task-based videos too
    link: String, //these need to be passed and can't be extracted from this.task as the component is used for non-task-based videos too
    disclaimer: String,
    disclaimerSmallScreen: String,
    showDisclaimer: Boolean,
    videoMetaData: Object
  },
  data() {
    return {
      topBarHeight: 48,
      verticalMargin: 120,
      selectedStep: null,
      windowHeight: window.innerHeight,
      activeStep: null,
      currentTime: null,
      videoStarted: false,
      videoTimer: null,
      timeSpentWatching: 0,
      playerAndFooterHeight: 283
    };
  },
  mounted() {
    this.openModal();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  computed: {
    cardHeight() {
      return this.$vuetify.breakpoint.xs
        ? this.windowHeight
        : this.windowHeight - this.verticalMargin;
    },
    playerSelectionHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return this.cardHeight - this.topBarHeight - this.playerAndFooterHeight;
      }
      return this.cardHeight - this.topBarHeight;
    },
    playerHeight() {
      if (this.showExerciseSteps) {
        if (this.$vuetify.breakpoint.smAndDown) {
          return this.$vuetify.breakpoint.xs ? 200 : 300;
        }
        return "100%";
      }
      return this.cardHeight - 2 * this.topBarHeight;
    },
    showExerciseSteps() {
      return !!this.videoMetaData && this.videoMetaData.steps.length > 0;
    }
  },
  beforeDestroy() {
    this.closeModal();
  },
  methods: {
    ...mapMutations(["openModal", "closeModal"]),
    completeTask() {
      this.$emit("complete", this.timeSpentWatching);
    },
    completeVideo() {
      if (this.videoStarted) {
        this.completeTask();
      }
      this.$emit("close");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    setActiveStep(playerState) {
      if (playerState.state !== "VIDEO_PLAYING") {
        this.stopTimer();
      } else {
        if (!this.videoStarted) {
          this.videoStarted = true;
        }
        if (this.videoTimer === null) {
          this.startTimer();
        }
      }
      if (playerState.state === "VIDEO_ENDED") {
        this.completeVideo();
      }
      if (this.$refs.stepper)
        this.$refs.stepper.onYouTubeEvent(
          playerState.position,
          playerState.state
        );
    },
    startTimer() {
      this.videoTimer = setInterval(() => this.timeSpentWatching++, 1000);
    },
    stopTimer() {
      clearInterval(this.videoTimer);
      this.videoTimer = null;
    },
    onStepClicked(step) {
      this.$refs.vitruePlayer.onStepClicked(step);
    },
    onRestartClicked() {
      this.$refs.vitruePlayer.onRestartClicked();
    }
  }
};
</script>

<style scoped>
.disclaimer {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button.full-width {
  width: 100%;
}
</style>
