var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: "height:40px;width:40px;" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 37 37" },
        attrs: {
          version: "1.1",
          id: "tick",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          viewBox: "0 0 37 37",
          "xml:space": "preserve"
        }
      },
      [
        _c("polyline", {
          staticClass: "path",
          class: _vm.trigger ? "tick" : "",
          staticStyle: {
            fill: "none",
            stroke: "#3FB288",
            "stroke-width": "3",
            "stroke-linejoin": "round",
            "stroke-miterlimit": "10"
          },
          attrs: { points: "\n\t11.6,20 15.9,24.2 26.4,13.8 " }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }