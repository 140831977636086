var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { "overflow-y": "scroll" },
      style: "max-height:" + _vm.maxHeight + "px"
    },
    _vm._l(_vm.steps, function(step) {
      return _c(
        "v-card",
        {
          key: step.sequence,
          staticStyle: { position: "relative" },
          attrs: {
            id: "step" + step.sequence,
            height: _vm.$vuetify.breakpoint.smAndDown ? "100px" : "200px",
            rounded: "0"
          },
          on: {
            click: function($event) {
              return _vm.stepSelected(step)
            }
          }
        },
        [
          _c("v-overlay", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: step.sequence <= _vm.currentStepId,
                expression: "step.sequence <= currentStepId"
              }
            ],
            class: _vm.getOverlayClass(step),
            style:
              "width:" +
              (_vm.currentStepId === step.sequence
                ? _vm.currentStepProgress
                : 100) +
              "%;--duration:" +
              _vm.getRemainingDuration(step),
            attrs: {
              id: "bar" + step.sequence,
              absolute: "",
              color:
                _vm.currentStepId === step.sequence
                  ? "primary"
                  : "vitruePaleGrey",
              opacity: "1",
              "data-style": "smooth",
              "z-index": 1
            }
          }),
          _c("v-hover", {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var hover = ref.hover
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "fill-height unselectable text-h5",
                          class: _vm.getStepTypography(step, hover),
                          staticStyle: { "z-index": "2", position: "relative" },
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              class: _vm.$vuetify.breakpoint.smAndDown
                                ? "px-5"
                                : "pl-3",
                              attrs: {
                                cols: "auto",
                                md: _vm.showTick(step) ? 2 : 4,
                                "align-self": "center"
                              }
                            },
                            [
                              _vm.currentStepId === step.sequence
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.formattedCurrentTimeLeft))
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.getCurrentStepDuration(step)) +
                                        "s"
                                    )
                                  ])
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "flex-grow-1 flex-shrink-0 px-2",
                              attrs: { cols: "8", "align-self": "center" }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t(step.title)) + " ")]
                          ),
                          _vm.showTick(step)
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "flex-grow-0 flex-shrink-1",
                                  attrs: {
                                    id: "tickContainer",
                                    cols: "1",
                                    "align-self": "center"
                                  }
                                },
                                [
                                  _c("animated-tick", {
                                    attrs: { trigger: _vm.animateTick(step) }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }