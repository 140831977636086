var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { id: "disclaimer" } },
    [
      _c(
        "v-card-title",
        { staticClass: "justify-center text-h4 pt-12" },
        [
          _c("v-img", {
            staticClass: "mr-3",
            attrs: {
              "max-height": "50px",
              "max-width": "50px",
              contain: "",
              src: require("@/assets/images/wellness/warning.png")
            }
          }),
          _vm._v(" " + _vm._s(_vm.$t("wellness.v2.disclaimerTitle")) + " ")
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticStyle: { "min-height": "75%" },
          attrs: { justify: "center", "no-gutters": "" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "text-body-1 text-center",
              attrs: { cols: "10", "align-self": "center" },
              domProps: {
                innerHTML: _vm._s(_vm.$t("wellness.v2.fullDisclaimer"))
              }
            },
            [
              _c("p", {
                staticClass: "mb-0",
                domProps: {
                  innerHTML: _vm._s(_vm.$t("wellness.v2.fullDisclaimer"))
                }
              })
            ]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "py-7", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex justify-center px-2", attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    id: "agree",
                    color: "primary",
                    width: "100%",
                    "max-width": "200px",
                    rounded: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.$emit("accepted")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-2", attrs: { color: "white" } },
                    [_vm._v("mdi-check")]
                  ),
                  _vm._v(" " + _vm._s(_vm.$t("wellness.v2.agree")) + " ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }