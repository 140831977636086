var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showDisclaimer
        ? _c("disclaimer", {
            on: {
              close: function($event) {
                return _vm.$emit("close")
              },
              accepted: function($event) {
                return _vm.$emit("accepted")
              }
            }
          })
        : _c(
            "v-card",
            {
              attrs: {
                color: "black",
                rounded: _vm.$vuetify.breakpoint.xs ? "0" : ""
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "close-button",
                  attrs: {
                    id: "close-button",
                    icon: "",
                    "x-large": _vm.$vuetify.breakpoint.mdAndUp,
                    color: "white"
                  },
                  on: { click: _vm.completeVideo }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("div", { staticClass: "row-header" }, [
                _c(
                  "p",
                  {
                    staticClass: "white--text text-h5 ml-3 py-2 mb-0",
                    attrs: { id: "title" }
                  },
                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                )
              ]),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex grow flex-nowrap flex-column",
                      attrs: {
                        cols: "12",
                        md: _vm.showExerciseSteps ? 8 : 12,
                        lg: _vm.showExerciseSteps ? 9 : 12
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "grow", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("VitrueYouTubePlayer", {
                                ref: "vitruePlayer",
                                attrs: {
                                  url: this.link,
                                  height: _vm.playerHeight,
                                  width: "100%",
                                  showControls: !_vm.showExerciseSteps
                                },
                                on: { VIDA_YOUTUBE_EVENT: _vm.setActiveStep }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "row footer white--text pa-2 shrink",
                          attrs: {
                            id: "footer",
                            "no-gutters": "",
                            align: "center"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", sm: "8", md: "6", lg: "4" }
                            },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        var value = ref.value
                                        return [
                                          _c(
                                            "div",
                                            _vm._g(
                                              _vm._b(
                                                { staticClass: "d-flex" },
                                                "div",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "disclaimer",
                                                  attrs: { id: "disclaimer" }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.disclaimer) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "white" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      value
                                                        ? "mdi-chevron-up"
                                                        : "mdi-chevron-down"
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-3",
                                      attrs: { dark: "" }
                                    },
                                    [_vm._v(" " + _vm._s(_vm.disclaimer) + " ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "4",
                                md: "3",
                                "offset-md": "3",
                                lg: "2",
                                "offset-lg": "6"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "full-width",
                                  attrs: { color: "white", outlined: "" },
                                  on: { click: _vm.onRestartClicked }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(" mdi-restart ")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "wellness.v2.painCoachVideos.startOver"
                                        )
                                      ) +
                                      " "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showExerciseSteps
                    ? _c(
                        "v-col",
                        {
                          attrs: {
                            id: "exerciseSteps",
                            cols: "12",
                            md: "4",
                            lg: "3"
                          }
                        },
                        [
                          _c("video-stepper", {
                            ref: "stepper",
                            attrs: {
                              id: "playerSelection",
                              maxHeight: _vm.playerSelectionHeight,
                              totalDuration: _vm.videoMetaData.totalDuration,
                              exerciseSteps: _vm.videoMetaData.steps,
                              stepSelected: _vm.onStepClicked
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }